import Axios from 'axios'
import { linkUsed } from './link'


//Change to /allref to make it the main admin.
export async function getAllRef(setRefData:any) {
    await Axios.get(`${linkUsed()}/allref`).catch((err) => {
        if (err) throw err
    }).then((data:any) => {
        setRefData(data.data)
    })
}

//Change to /get-all to make it the main admin.
// This grabs all agents but from Oman only
// Take a look at ocr-backend
export async function getAllProspect(setRefData:any) {
    await Axios.get(`${linkUsed()}/get-all`).catch((err) => {
        if (err) throw err
    }).then((data:any) => {
        setRefData(data.data)
    })
}
