import React, {useState, useEffect} from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Select,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Text,
    Td,
    Th,
    Thead,
    Table,
    Tbody,
    Link,
    Tr,
    TableCaption,
    Tfoot
  } from "@chakra-ui/react"
  import Axios from 'axios'
import { linkUsed } from '../utilsAxios/link'
import { InfoOutlineIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { getAllRef } from '../utilsAxios/GetData';
import { vgLink } from '../utilsAxios/vgLink';

  

export default function RegisteredInfos({reff,phone, data}:any) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [referred, setReferred] = useState<any>()
    const [Agent, setAgent] = useState(false);
    const [title, setTitle] = useState('');
    const [agentList, setAgentList] = useState<any>([])
    const [assigned, setAssigned] = useState<any>(null)
    const [refData, setRefData] = useState<any>(null);
    const [referredLayout, setReferredLayout] = useState<any>(null)

    async function OpenModal(reff:any, data:any) {

      if (reff) {
        await Axios.post(`${linkUsed()}/get-agent`, {code: reff} ).catch((err) => {
          if (err) console.log(err);
      }).then((res:any) => {
          setReferred(res.data)
          setAgent(true)
          onOpen();
      })
      } else if(!reff) {
        setAgent(false)
        onOpen();
      }

    }

    useEffect(() => {
      if (refData) {
        setAgentList(
          refData.map((each:any) => {
            return <option value={each.code}>{each.name}, {each.code}</option>
          })
        )
      }
    }, [refData])

    async function sendAssigned(registrantPhone:string) {
      onClose();
      await Axios.post(`${linkUsed()}/set-agent`, {code:assigned, phone: registrantPhone, name: data.full_name, email: data.email}).catch((err) => {
        if (err) console.log(err)
      }).then((result) => {
      })
    }



    useEffect(() => {
        if (referred) {
          setReferredLayout(<>                  <Td>
            <Tr color="#90EE90" height="7">{referred.name}</Tr>
            <Tr color="#90EE90" height="7">{referred.email}</Tr>
            <Tr color="#90EE90" height="7">{referred.phone}</Tr>
            <Tr color="#90EE90" height="7">
              <Link
                href={`${vgLink()}/register-prospect/${referred.code}`}
                isExternal>
                {referred.code} <ExternalLinkIcon mx="2px" />
              </Link>
            </Tr>
            <Tr color="#90EE90" height="7">{referred.nationality}</Tr>
            <Tr color="#90EE90" height="7">{referred.referrals}</Tr>
          </Td></>)
         }
        
    }, [referred])

    useEffect(() => {
      if (!reff) {
        getAllRef(setRefData)
        setTitle('No Agent')
      } else (setTitle(reff))
    }, [reff])

    return (
      <>
        <Button bg="#90EE90" color={title !== 'No Agent' ? 'black' : 'grey'} onClick={() => OpenModal(reff,data)}>{title}</Button>
  
        <Modal size="3xl" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader >Referred</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Table  variant="simple">
  <TableCaption>Agent</TableCaption>
  <Thead>

  </Thead>
  {Agent ?   <Tbody >
  <Td>
                    <Tr color="#90EE90" height="7" fontWeight="bold">
                      Full Name
                    </Tr>
                    <Tr color="#90EE90" height="7" fontWeight="bold">
                      Email
                    </Tr>
                    <Tr color="#90EE90" height="7" fontWeight="bold">
                      Phone
                    </Tr>
                    <Tr color="#90EE90" height="7" fontWeight="bold">
                      Link
                    </Tr>
                    <Tr color="#90EE90" height="7" fontWeight="bold">
                      Country
                    </Tr>
                    <Tr color="#90EE90" height="7" fontWeight="bold">
                      Referred
                    </Tr>
                  </Td>
      {referredLayout}
  </Tbody> : 
  
  
  <Tbody >
    <Tr w="100%" justify="center" align="center">
      <Select onClick={(e:any) => {setAssigned(e.target.value); console.log(e.target.value)}} placeholder="Available Agents">
        {agentList}

      </Select>
    <Button onClick={() => sendAssigned(phone)} m="5">Assign Agent</Button>
    </Tr>
    </Tbody>}

  <Tfoot>

  </Tfoot>
</Table >
            </ModalBody>
  
            <ModalFooter>
              <Button  colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        </>
    )
  }
