import React, {useEffect} from "react"
import 'dotenv'
import {
  ChakraProvider,
  extendTheme
} from "@chakra-ui/react"
import {Switch, Route, useHistory} from 'react-router-dom'
import Axios from 'axios'
import Referrals from "./Referrals";
import Header from "./header/Header";
import Login from "./Login";
import Registered from "./comps/Registered";
import { linkUsed } from "./utilsAxios/link"

const theme = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: "dark"
  }
});






export const App = () => {
  const [username, setUserName] = React.useState('');
  const [password, setPassword] = React.useState('')
  const [id, setId] = React.useState<number | null>(null)
  const [loginStatus, setLoginStatus] = React.useState(false)


  

  const history = useHistory()

  function LogToAccount(e:any) {
    e.preventDefault()
    Axios.post(`${linkUsed()}/login`, {
      username,
      password
    }).then((response) => {
      if (response.data.auth) {
        setId(response.data.id)
        setLoginStatus(response.data.auth)
        localStorage.setItem("token",response.data.token)
        AuthenticateUser()
      }
    })
  }

  function AuthenticateUser(){
    Axios.get(`${linkUsed()}/auth-user`, {
      headers: {
        "x-access-token": localStorage.getItem("token")
      }
    }).then((response) => {
      if (response.data.auth) {
        setLoginStatus(response.data.auth)
        history.push('/analytics')
      } else {
        history.push('/')
      }
    })
  }

  
  function LogOut(e:any) {
    e.preventDefault()

   localStorage.clear()
   setLoginStatus(false)
    history.push('/')

  }

  React.useEffect(() => {
    AuthenticateUser()

  }, [])



  return(<ChakraProvider theme={theme}>
    {/* <Header setLoginStatus={() => {setLoginStatus(false);history.push('/');localStorage.clear();}} loginStatus={loginStatus}/> */}
    <Header setLoginStatus={LogOut} loginStatus={loginStatus}/>
    {/* <button onClick={AuthenticateUser}>Auth</button>
    <button onClick={() => localStorage.clear()}>clear</button> */}
    <Switch>
      <Route exact path="/">
        <Login LogToAccount={LogToAccount} username={username} password={password} setUserName={setUserName} setPassword={setPassword}/>
      </Route>
      <Route path="/analytics">
      <Referrals loginStatus={loginStatus}/>  
      </Route>

      <Route path="/registered">
      <Registered loginStatus={loginStatus}/>
      </Route>
    </Switch>
  </ChakraProvider>)
}
