import React, {useState, useEffect, useMemo} from 'react'
import Table from "../Table_Registered";
import makeData from "../Table_Registered/makeData";
import {Box,Center, Text, TableCaption, Thead, Tr, Th, Tbody, Td,Tfoot, Table as Custom} from '@chakra-ui/react'
import Axios from 'axios'
import { getAllProspect } from '../utilsAxios/GetData';

export default function Registered({loginStatus}:any) {
    const [refData, setRefData] = useState<any>({})
    const [datas, setData] = useState<any>()


    // let mockData = [
    //     {
    //        name: 'John Doe',
    //        email: 'john@gmail.com',
    //        code: 'tw01',
    //        referrals: 1
    //     },
    //     {
    //         name: 'Sabrina',
    //         email: 'sabby@gmail.com',
    //         code: 'cn01',
    //         referrals: 5
    //      },
    //      {
    //         name: 'Adam',
    //         email: 'adam@gmail.com',
    //         code: 'cn02',
    //         referrals: 12
    //      }
    // ]

    const columns = [
        {
          Header: "ID",
          accessor: "ID"
        },
        {
          Header: "Name",
          accessor: "full_name"
        },
        {
          Header: "Email",
          accessor: "email"
        },
        {
          Header: "Phone",
          accessor: "phone"
        },
        {
            Header: "Nationality",
            accessor: "nationality"
        },
        {
          Header: "Appointment",
          accessor: "appointment"
        },
        {
            Header: "WeChat ID",
            accessor: "wechat"
        },
        {
            Header: "Agent Referral",
            accessor: "referral"
        },
      ];

    useEffect(() => {
        if (loginStatus) {
          getAllProspect(setRefData)
        }
    }, [loginStatus])

    // async function getData() {
    //     await Axios.get(`${linkUsed()}/get-all`).catch((err) => {
    //         if (err) throw err
    //     }).then((data:any) => {
    //         setRefData(data.data)
    //     })
    // }


    useEffect(() => {
        if (refData) {
            setData(<Table columns={columns} data={data} />)
        }
    }, [refData])
    // const data = React.useMemo(() => makeData(refData), []);
    const data = makeData(refData)

    return (
<Box color="white" d="flex" justifyContent="center" bg="#1A202C" h="100vh" m={5}>
    <Box bg="#1A202C" w="80vw">
        <Text fontSize="2xl">Prospects</Text>
        {datas}
    </Box>
        </Box>
    )
}
