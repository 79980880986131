import React, {useState, useEffect} from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Td,
    Thead,
    Table,
    Tbody,
    Link,
    Tr,
    Text,
    Input,

  } from "@chakra-ui/react"
  import Axios from 'axios'
  import { InfoOutlineIcon, ExternalLinkIcon } from "@chakra-ui/icons";
  import { linkUsed } from '../utilsAxios/link';
import { vgLink } from '../utilsAxios/vgLink';

  

export default function Approve({data, getData}:any) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [code,setCode] = useState('')
    const [error, setError] = useState<string|null>(null)
    const [referred, setReferred] = useState<any>()
    const [referredLayout, setReferredLayout] = useState<any>(null)

    async function OpenModal() {
      onOpen();
    }

    async function ApproveAgent() {
      if (code.length > 5) {
              await Axios.post(`${linkUsed()}/approve-agent`, {code, email: data.email, status: 1} ).catch((err) => {
              // await Axios.post(`http://localhost:3000/approve-agent`, {code, email: data.email, status: 1} ).catch((err) => {
            if (err) throw err;
        }).then((res:any) => {
            if (res.data.approved) {
              getData()
              onClose()
              console.log(res.data.approved)
              setError(null)
            } else if (!res.data.approved) {
              setError(res.data.message)

            }


        })
      } else {
        setError('Please provide 6 alphanumeric code.')
      }
    }

    useEffect(() => {

    }, [])

    return (
      <>
        <Button color="black" bg="lightgreen" onClick={() => OpenModal()}>Approve</Button>
  
        <Modal size="3xl" blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Approve Agent?</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <Table variant="simple">
              <Thead>

              </Thead>
              <Tbody>
              <Td>
                    <Tr height="7" fontWeight="bold">
                      Full Name
                    </Tr>
                    <Tr height="7" fontWeight="bold">
                      Email
                    </Tr>
                    <Tr height="7" fontWeight="bold">
                      Phone
                    </Tr>
                    <Tr height="7" fontWeight="bold">
                      Code
                    </Tr>
                    <Tr height="7" fontWeight="bold">
                      Link Once Approved
                    </Tr>
                    <Tr height="7" fontWeight="bold">
                      Country
                    </Tr>
                  </Td>
                  <Td>
                    <Tr height="7">{data.name}</Tr>
                    <Tr height="7">{data.email}</Tr>
                    <Tr height="7">{data.phone}</Tr>
                    <Tr height="7"><Input value={code} onChange={(e:any) => {if (e.target.value.length < 7) {setCode(e.target.value)}}} /></Tr>
                    <Tr height="7">
                      <Link
                        href={`${vgLink()}/register-prospect/${code}`}
                        isExternal>
                        {vgLink()}/register-prospect/{code} <ExternalLinkIcon mx="2px" />
                      </Link>
                    </Tr>
                    <Tr height="7">{data.nationality}</Tr>
                  </Td>
              </Tbody>
            </Table>
            {error !== null ? <Text color="red" textAlign="center">{error}</Text> : null}
            </ModalBody>
  
            <ModalFooter>
            <Td>
                  <Tr
                    >
                    <Button onClick={ApproveAgent}  color="black" bg="lightgreen">
                      Approve
                    </Button>
                  </Tr>
                </Td>
                <Td>
                  <Tr
                    >
                    <Button onClick={onClose}>Cancel</Button>
                  </Tr>
                </Td>

            </ModalFooter>
          </ModalContent>
        </Modal>
        </>
    )
  }
